import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Layout from "./components/Layout";
import NotFound from "./pages/404";
import HomePage from "./pages/HomePage";

// Lazy load components
const Games = lazy(() => import("./pages/Games"));
const MinecraftBudgetPage = lazy(() => import("./pages/MinecraftBudget"));
const MinecraftPremiumPage = lazy(() => import("./pages/MinecraftPremium"));
const MinecraftHighEndPage = lazy(() => import("./pages/MinecraftHighEnd"));
const VPSHostingShowcase = lazy(() => import("./pages/VPS"));
const WebHosting = lazy(() => import("./pages/WebHosting"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const Imprint = lazy(() => import("./pages/Imprint"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./pages/TermsOfService"));
const GDPR = lazy(() => import("./pages/GDPR"));
const AUP = lazy(() => import("./pages/Aup"));
const AboutPage = lazy(() => import("./pages/About"));
const PartnersPage = lazy(() => import("./pages/Partners"));

// simple component wrapper for lazy-loaded routes
function LazyComponent({ component: Component }) {
  return (
    <React.Suspense fallback={
      <div className="min-h-screen">
      </div>
    }>
      <Component />
    </React.Suspense>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/minecraft" element={<LazyComponent component={Games} />} />
            <Route path="/minecraft/budget" element={<LazyComponent component={MinecraftBudgetPage} />} />
            <Route path="/minecraft/premium" element={<LazyComponent component={MinecraftPremiumPage} />} />
            <Route path="/minecraft/high-end" element={<LazyComponent component={MinecraftHighEndPage} />} />
            <Route path="/vps" element={<LazyComponent component={VPSHostingShowcase} />} />
            <Route path="/webhosting" element={<LazyComponent component={WebHosting} />} />
            <Route path="/about" element={<LazyComponent component={AboutPage} />} />
            <Route path="/partners" element={<LazyComponent component={PartnersPage} />} />
            <Route path="/cookie-policy" element={<LazyComponent component={CookiePolicy} />} />
            <Route path="/imprint" element={<LazyComponent component={Imprint} />} />
            <Route path="/privacy-policy" element={<LazyComponent component={PrivacyPolicy} />} />
            <Route path="/terms-of-service" element={<LazyComponent component={TermsOfService} />} />
            <Route path="/gdpr" element={<LazyComponent component={GDPR} />} />
            <Route path="/aup" element={<LazyComponent component={AUP} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App;